import React from 'react';
import {
  Bullet,
  Card,
  Text,
  Pill,
  DateFormat,
  BoxKeyValue,
  Number,
} from '@blastradius/ui';
import {
  Incident,
  IncidentStatuses,
  IncidentTypes,
} from '@customer-web-app/domains/incidents/models/incident';
import incidentTypesCopies from '@customer-web-app/domains/incidents/mappers/incident-types-copies';
import Skeleton from 'react-loading-skeleton';
import typeColorsMapper from '@customer-web-app/domains/incidents/mappers/type-colors-mapper';
import Link from 'next/link';
import IncidentProgressStepBar from '@customer-web-app/domains/incidents/components/incident-progress-step-bar';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import getIncidentStep from '@customer-web-app/domains/shared/services/get-incident-step';
import classNames from 'classnames';
import getIncidentDetailsEntryPointURL from '@customer-web-app/domains/incident-details/services/get-incident-details-entry-point-url';
import IncidentDetailsIconContained from '@customer-web-app/domains/incident-details/components/incident-details-icon-contained';
import IncidentAssigneeDropdown from '@customer-web-app/domains/incident-details/components/incident-assignee-dropdown';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

type Props = {
  incident: Incident;
  loading?: boolean;
};

export function IncidentDetails({
  incident,
  loading,
  ...props
}: {
  incident: Incident;
  loading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) {
  const className = classNames(
    'flex items-center justify-between py-4 px-8 z-0',
    props.className,
  );

  return (
    <div className={className}>
      {incident?.incidentType !== IncidentTypes.Generic && (
        <div className="grid items-center grid-cols-[1fr_1fr_1fr] w-1/2 h-8">
          <BoxKeyValue
            label="Identities"
            value={<Number value={incident?.iamIdentitiesCount || 0} />}
            loading={loading}
            data-testid="identities-counter"
            className={classNames({
              '!text-gray-500': !incident?.iamIdentitiesCount,
            })}
          />
          <BoxKeyValue
            label="Devices"
            value={<Number value={incident?.devicesCount || 0} />}
            loading={loading}
            data-testid="devices-counter"
            className={classNames({
              '!text-gray-500': !incident?.devicesCount,
            })}
          />
          <BoxKeyValue
            label="Apps"
            value={<Number value={incident?.applicationsCount || 0} />}
            loading={loading}
            data-testid="applications-counter"
            className={classNames({
              '!text-gray-500': !incident?.applicationsCount,
            })}
          />
        </div>
      )}
      <IncidentProgressStepBar
        incident={incident}
        loading={loading}
        className="grid-cols-[1fr_1fr_1fr_1fr] 2.5xl:gap-4 w-[28rem] 2.5xl:w-[46.75rem]"
      />
    </div>
  );
}

const IncidentCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  incident,
  loading,
  ...props
}) => {
  const { incidentsEnableSummaryByContext, incidentsEnableBulkOperations } =
    useFeatureFlags();
  const {
    user: { tenantId },
  } = useAuthentication();

  const incidentDetailsEntryPoint = getIncidentDetailsEntryPointURL(
    incident?.incidentType,
    incidentsEnableSummaryByContext as string[],
  );

  const href = `/incidents/${tenantId}/${incident?.id}/${incidentDetailsEntryPoint}`;

  const getGenericIncidentCallout = () => {
    return `${
      incident.alerts?.[0].alertConnectorVendorCode ??
      incidentTypesCopies[incident?.incidentType]
    } INCIDENT`;
  };
  const incidentCallout =
    incident?.incidentType === IncidentTypes.Generic
      ? getGenericIncidentCallout()
      : `${incidentTypesCopies[incident?.incidentType]} INCIDENT`;

  return (
    <Link href={href} passHref legacyBehavior>
      <Card
        {...props}
        border={
          incident?.isLive &&
          incident?.incidentStatus === IncidentStatuses.Opened
            ? 'red'
            : null
        }
        clickable={!loading}
        as="a"
        className={classNames('select-none', {
          'pointer-events-none': loading,
        })}
      >
        <header
          className={classNames('py-4', {
            'px-8': !incidentsEnableBulkOperations,
            'px-14': incidentsEnableBulkOperations,
          })}
        >
          <section className="flex items-center gap-4">
            <IncidentDetailsIconContained
              incident={incident}
              withPill
              loading={loading}
              size="regular"
              livePillBackground="bg-gray-50 dark:bg-gray-950"
              liveIconColor="fill-gray-950 dark:fill-white"
              className="!w-13 !h-13"
            />

            <div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {!loading &&
                  incident?.incidentStatus === IncidentStatuses.Opened &&
                  incident?.isLive ? (
                    <Pill color="red" className="mr-2" size="small">
                      <Bullet color="white" className="mr-1" />
                      <Text type="label-caps" size="small" color="text-white">
                        Live
                      </Text>
                    </Pill>
                  ) : (
                    incident?.incidentStatus === IncidentStatuses.OnHold && (
                      <Pill color="gray" className="mr-2" size="small">
                        On Hold
                      </Pill>
                    )
                  )}

                  <Text
                    type="label-caps"
                    {...typeColorsMapper[getIncidentStep(incident)]}
                  >
                    {!loading ? (
                      incidentCallout
                    ) : (
                      <Skeleton width={57} height={12} />
                    )}
                  </Text>

                  <Text
                    type="body"
                    size="small"
                    color="text-gray-500"
                    className="ml-2"
                  >
                    {!loading ? (
                      incident?.incidentNumber
                    ) : (
                      <Skeleton width={57} height={12} />
                    )}
                  </Text>
                </div>

                <div className="flex items-start gap-4 absolute top-4 right-8">
                  <div
                    className="flex items-center"
                    data-testid="incident-card-assignee"
                  >
                    <IncidentAssigneeDropdown
                      incident={incident}
                      isLoadingIncident={loading}
                    />
                  </div>

                  <div
                    className="flex items-center gap-1"
                    data-testid="incident-card-detected-at"
                  >
                    {!loading ? (
                      <>
                        <Text type="body" size="small" color="text-gray-500">
                          Detected at
                        </Text>
                        <Text type="body" size="small">
                          <DateFormat
                            value={incident?.createdOn}
                            pattern="long"
                          />
                        </Text>
                      </>
                    ) : (
                      <Skeleton height={14} width={182} />
                    )}
                  </div>
                </div>
              </div>

              {!loading ? (
                <Text
                  as="h2"
                  type="heading"
                  size="x-small"
                  render={({ tooltipId, tooltipStyle, ref }) => (
                    <div
                      className="mt-4 line-clamp-2 max-w-[37rem] break-words"
                      data-tooltip-id={tooltipId}
                      data-tooltip-content={incident?.incidentName}
                      ref={ref}
                      {...tooltipStyle}
                    >
                      {incident?.incidentName}
                    </div>
                  )}
                />
              ) : (
                <Skeleton width={350} height={20} />
              )}
            </div>
          </section>
        </header>

        {incident?.incidentStatus !== IncidentStatuses.Pending && (
          <div
            className={classNames({
              'pl-24 pr-6': incidentsEnableBulkOperations,
              'pl-18 pr-6': !incidentsEnableBulkOperations,
            })}
            data-testid="incident-card-details"
          >
            <IncidentDetails incident={incident} loading={loading} />
          </div>
        )}
      </Card>
    </Link>
  );
};

export default IncidentCard;
